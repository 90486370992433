<template>
  <v-card>
    <v-card-title>
      Instantmessages
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="instantmessages"
      >
        <template #item.actions="{item}">
          <v-btn
            icon
            :disabled="item.active"
            @click="editMessage(item)"
          >
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
        </template>

        <template #item.languages="{item}">
          {{ getLanguageCodes(item) }}
        </template>

      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        depressed
        small
        color="primary"
        @click="createMessage()"
      >
        new Message
      </v-btn>
    </v-card-actions>
    <InstantmessageDetail
      :visible="dialog_instantmessageDetail"
      :is-new="isNew"
      :data="selected_instantmessage"
      :is-host="true"
      @close="dialog_instantmessageDetail = false"
      @reload="loadInstantmessages"
    />
  </v-card>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import Instantmessage from '../../../types/Instantmessage'
import _ from 'lodash'
import InstantmessageDetail from './InstantmessageDetail'
import instantmessageService from '@/services/Instantmessage.service'

export default {
  name: 'InstantmessagesCard',
  components: {InstantmessageDetail},
  data() {
    return {
      loading_instantmessages: false,
      selected_instantmessage: {},
      dialog_instantmessageDetail: false,
      isNew: false,
      instantmessages: [],
      headers: [
        {
          text: 'title',
          value: 'title'
        },
        {
          text: 'languages',
          value: 'languages'
        },
        {
          text: 'description',
          value: 'description'
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false
        }
      ]
    }
  },
  computed: {
    ...mapState('authentication', ['user']),
    ...mapState('instantmessage', ['dialogs', 'instantmessagesHost']),
  },
  mounted() {
    this.loadInstantmessages()
  },
  methods: {
    async loadInstantmessages() {
      this.loading_instantmessages = true
      this.instantmessages = await instantmessageService.loadInstantmessagesFromHost()
      this.loading_instantmessages = false
    },

    ...mapMutations('instantmessage', ['setDialogsDetail', 'setInstantmessage']),

    editMessage(item) {
      this.selected_instantmessage = _.clone(item)
      this.isNew = false
      this.dialog_instantmessageDetail = true
    },
    createMessage() {
      this.selected_instantmessage = new Instantmessage(null, this.user.host_id)
      this.isNew = true
      this.dialog_instantmessageDetail = true
    },

    publishMessage(item) {
      this.publishInstantmessage({
        project_id: item.projectId,
        instant_id: item.id
      })
    },
    unpublishMessage(item) {
      this.unpublishInstantmessage({
        project_id: item.projectId,
        instant_id: item.id
      })
    },

    getLanguageCodes(item) {
      return _.map(item.instantmessageTranslations, 'language.code').join(', ')
    }

  },

}
</script>

<style scoped>

</style>
