var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"500px","match-height":"","scrollable":"","loading":_vm.loaders.hostdetail}},[_c('v-card-title',[_vm._v(" Host details ")]),_c('v-card-text',[_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-form',{attrs:{"lazy-validation":""}},[_c('v-text-field',{attrs:{"label":"PIN","readonly":"","append-icon":"mdi-autorenew","hint":"pins are always autogenerated to ensure maximum securtity"},on:{"click:append":_vm.generateNewPin},model:{value:(_vm.form.pin),callback:function ($$v) {_vm.$set(_vm.form, "pin", $$v)},expression:"form.pin"}}),_c('v-text-field',{attrs:{"label":"name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('v-autocomplete',{attrs:{"label":"font","items":_vm.available_fonts},model:{value:(_vm.form.font),callback:function ($$v) {_vm.$set(_vm.form, "font", $$v)},expression:"form.font"}})],1)],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-toolbar',{attrs:{"flat":"","color":_vm.form.header_color}},[_c('v-toolbar-title',{attrs:{"color":_vm.form.font_color}},[_c('span',{style:({color: _vm.form.font_color, 'font-family': _vm.form.font})},[_vm._v(" "+_vm._s(_vm.form.name)+" ")]),_c('v-subheader',{staticClass:"text-right",style:({color: _vm.form.font_color, height: 'auto'})},[_vm._v(" powered by opera.guru ")])],1)],1),_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"flat":"","color":_vm.form.background_color}},[_c('v-card-text',[_c('span',{style:({color: _vm.form.body_font_color})},[_vm._v(" Lorem Ipsum dolor sit amet. ")])])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-simple-table',{attrs:{"dense":""}},[_c('tbody',[_c('tr',[_c('td',[_vm._v(" Header Color ")]),_c('td',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"color":_vm.form.header_color,"dark":"","small":""}},on))]}}])},[_c('v-color-picker',{staticClass:"mx-auto",attrs:{"hide-canvas":"","hide-inputs":"","show-swatches":""},on:{"input":function($event){return _vm.changeColor('header_color')}},model:{value:(_vm.selected_color.header_color),callback:function ($$v) {_vm.$set(_vm.selected_color, "header_color", $$v)},expression:"selected_color.header_color"}})],1)],1),_c('td'),_c('td',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_vm._v(" Background Color ")]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [[_c('v-chip',_vm._g({attrs:{"color":_vm.form.background_color,"dark":"","small":""}},on))]]}}])},[_c('v-color-picker',{staticClass:"mx-auto",attrs:{"hide-canvas":"","hide-inputs":"","show-swatches":""},on:{"input":function($event){return _vm.changeColor('background_color')}},model:{value:(_vm.selected_color.background_color),callback:function ($$v) {_vm.$set(_vm.selected_color, "background_color", $$v)},expression:"selected_color.background_color"}})],1)],1)]),_c('tr',[_c('td',[_vm._v(" Header Font Color ")]),_c('td',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"color":_vm.form.font_color,"dark":"","small":""}},on))]}}])},[_c('v-color-picker',{staticClass:"mx-auto",attrs:{"hide-canvas":"","hide-inputs":"","show-swatches":""},on:{"input":function($event){return _vm.changeColor('font_color')}},model:{value:(_vm.selected_color.font_color),callback:function ($$v) {_vm.$set(_vm.selected_color, "font_color", $$v)},expression:"selected_color.font_color"}})],1)],1),_c('td'),_c('td',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_vm._v(" Body Font Color ")]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [[_c('v-chip',_vm._g({attrs:{"color":_vm.form.body_font_color,"dark":"","small":""}},on))]]}}])},[_c('v-color-picker',{staticClass:"mx-auto",attrs:{"hide-canvas":"","hide-inputs":"","show-swatches":""},on:{"input":function($event){return _vm.changeColor('body_font_color')}},model:{value:(_vm.selected_color.body_font_color),callback:function ($$v) {_vm.$set(_vm.selected_color, "body_font_color", $$v)},expression:"selected_color.body_font_color"}})],1)],1)]),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v(" Contrast ratio: "+_vm._s(_vm.contrast(_vm.hexToRgb(_vm.form.font_color), _vm.hexToRgb(_vm.form.header_color)).toFixed(2))+" ")]),_c('td'),_c('td',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"colspan":"2"}},[_vm._v(" Contrast ratio: "+_vm._s(_vm.contrast(_vm.hexToRgb(_vm.form.body_font_color), _vm.hexToRgb(_vm.form.background_color)).toFixed(2))+" ")])]),_c('tr',[_c('td',{attrs:{"colspan":"5"}},[_c('span',[_vm._v("Info: contrast ratio should be higher than 7 to ensure a good readability")])])])])])],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","small":"","color":"primary"},on:{"click":_vm.save}},[_vm._v(" save ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }