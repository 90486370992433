<template>
  <v-card :height="ishostManagement ? '' : '400px'">
    <v-card-title>
      Users
    </v-card-title>
    <v-card-text>
      <v-data-table
        :items="users"
        :headers="headers"
        :loading="loading_users"
      >
        <template #item.actions="{item}">
          <v-btn
            icon
            @click="editUser(item)"
          >
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
        </template>
        <template #item.userRoles="{item}">
          {{ getRoleNames(item.userRoles) }}
        </template>

      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        depressed
        small
        color="primary"
        @click="createUser()"
      >
        new User
      </v-btn>
      <v-btn
        v-if="ishostManagement"
        depressed
        small
        color="secondary"
        @click="close()"
      >
        Close
      </v-btn>
    </v-card-actions>
    <UserDetailDialog
      :visible="dialogs_userdetail"
      :value="selected_user"
      :host-id="host_id"
      :is-new="newUser"
      @close="dialogs_userdetail = false"
      @reload="reload"
    />
  </v-card>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
import _ from 'lodash'
import UserDetailDialog from './UserDetailDialog'
import User from '../../types/User'
import hostService from '@/services/Host.service'

export default {
  name: 'Users',
  components: {UserDetailDialog},
  props: {
    ishostManagement: {
      default: false,
      type: Boolean
    },
    hostId: {
      type: Number,
      default: () => {
        return 0
      }
    }
  },
  data: () => ({
    users: [],
    loading_users: false,
    dialogs_userdetail: false,
    selected_user: {},
    newUser: false,
    headers: [
      {
        text: 'Username',
        value: 'username'
      },
      {
        text: 'Firstname',
        value: 'firstname'
      },
      {
        text: 'Lastname',
        value: 'lastname'
      },
      {
        text: 'Active',
        value: 'enabled'
      },
      {
        text: 'Roles',
        value: 'userRoles'
      },
      {
        text: '',
        value: 'actions',
        sortable: false
      },
    ]
  }),
  computed: {
    ...mapState('admin', ['loaders', 'dialogs', 'host_id']),
  },
  mounted() {
    this.loadUsersToHost()
  },
  methods: {
    async loadUsersToHost() {
      console.log('loadUsersToHost')
      this.loading_users = true
      this.users = await hostService.loadUserToHost(this.hostId)
      this.loading_users = false
    },


    editUser(user) {
      this.selected_user = user
      this.newUser = false
      this.dialogs_userdetail = true
    },
    getRoleNames(i) {
      return _.join(_.map(i, 'description'), ', ')
    },

    createUser() {
      this.selected_user = new User(this.host_id)
      this.newUser = true
      this.dialogs_userdetail = true
    },

    close() {
      this.$emit('close')
    },
    reload() {
      console.log('reload Users')
      this.loadUsersToHost()
    }
  }
}
</script>

<style scoped>

</style>
