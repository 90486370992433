<template>
  <v-dialog
    :value="visible"
    persistent
    max-width="500px"
  >
    <v-card>
      <v-card-title>
        {{ data.id ? 'edit' : 'create' }} user
      </v-card-title>
      <v-card-text>
        <v-form ref="User">
          <v-text-field
            v-model="data.firstname"
            label="Firstname"
            :rules="[rules.required(), rules.maxLength(50)]"
          />
          <v-text-field
            v-model="data.lastname"
            label="Lastname"
            :rules="[rules.required(), rules.maxLength(50)]"
          />
          <v-text-field
            v-model="data.username"
            label="Username"
            :rules="[rules.required(), rules.maxLength(50)]"
          />
          <v-text-field
            v-model="data.password"
            label="Password"
            :type="showPassword ? 'text' : 'password'"

            hint="[A-Z] [a-z] [0-9] [@$!%*§?&]"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
          />
          <!--                    :rules="[(data.id ? '' : rules.required()), rules.password]"-->
          <v-text-field
            v-model="data.password_confirmation"
            label="Password confirmation"

            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
          />
          <!--                    :rules="[(data.id ? '' : rules.required()) , rules.password, passwordconfirmationrule]"-->

        </v-form>

        <v-data-table
          :headers="headers"
          :items="data.userRoles"
        >
          <template #item.actions="{item}">
            <v-btn
              icon
              @click="confirmDelete(item)"
            >
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>

        </v-data-table>

      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          v-if="!isNew"
          depressed
          small
          color="primary"
          @click="dialog_userRole = true"
        >
          add Role
        </v-btn>
        <v-btn
          depressed
          small
          color="primary"
          :loading="loaders.save"
          @click="trySaveUser()"
        >
          Save
        </v-btn>
        <v-btn
          depressed
          small
          color="secondary"
          @click="close"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
    <UserRoleDialog
      :visible="dialog_userRole"
      @close="dialog_userRole = false"
      @addRole="addRole"
    />
  </v-dialog>
</template>

<script>
import User from '../../types/User'
import {mapActions, mapMutations, mapState} from 'vuex'
import _ from 'lodash'
import axios from 'axios'
import UserRoleDialog from './UserRoleDialog'
import ValidationRulesMixin from '@/mixins/ValidationrulesMixin'
import hostService from '@/services/Host.service'

export default {
  name: 'UserDetailDialog',
  components: {UserRoleDialog},
  mixins: [ValidationRulesMixin],
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    isNew: {
      default: false,
      type: Boolean
    },
    value: {
      default: Object,
      type: Object,
    },
    hostId: {
      type: Number,
      default: () => {
        return 0
      }
    }
  },
  data() {
    return {
      dialog_userRole: false,
      data: {},
      showPassword: false,
      headers: [
        {
          text: 'role',
          value: 'description'
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false
        },
      ]
    }
  },
  computed: {
    ...mapState('admin', ['loaders', 'dialogs', 'selected_user']),
    ...mapState('globals', ['roles']),

    passwordconfirmationrule() {
      return () => (this.data.password === this.data.password_confirmation) || 'Passwords must match'
    }
  },
  watch: {
    visible(val) {
      this.data = _.clone(this.value)
      if (this.data.id) {
        this.data.password = null
      }
    },
    selected_user() {
      this.data = _.clone(this.selected_user)
    }
  },
  methods: {
    ...mapMutations('admin', ['setDialogUserDetail', 'setDialogsUserRole']),
    ...mapActions('admin', ['saveUser', 'updateUser', 'removeRoleFromUser']),

    async trySaveUser() {
      if (!this.$refs.User.validate()) {
        return
      }

      if (this.data.id) {
        //user bereits in der Datenbank
        //anderer Endpoint

        try {
          await hostService.updateUser(this.hostId, this.data)
          this.$toast('user updated', {
            type: 'success',
            top: true,
            right: true,
            timeout: 3000,
          })
          this.$emit('reload');
          this.$emit('close');
        } catch (e) {
          this.$toast('update canceled', {
            type: 'error',
            top: true,
            right: true,
            timeout: 3000,
          })
        }


      } else {
        if (this.data.password !== this.data.password_confirmation) {
          return
        }
        try {
          await hostService.createUser(this.hostId, this.data)
          this.$toast('user created', {
            type: 'success',
            top: true,
            right: true,
            timeout: 3000,
          })
          this.$emit('reload');
          this.$emit('close');
        } catch (e) {
          this.$toast('creation canceled', {
            type: 'error',
            top: true,
            right: true,
            timeout: 3000,
          })
        }
      }
    },
    async addRole(e) {
      try {
        await hostService.addRoleToUser(this.value.hostId, this.value.id, e)
        this.$toast('role added', {
          type: 'success',
          top: true,
          right: true,
          timeout: 3000,
        })
        this.$emit('reload')
        this.$emit('close')
      } catch (e) {
        console.error(e)
      }
    },
    async confirmDelete(item) {
      console.log(item)
      this.$confirm('delete role?', {
        type: 'delete'
      })
        .then(async value => {
          if (value) {
            try {
              await hostService.removeRoleFromUser(this.value.hostId, this.value.id, item.id)
              this.$toast('role removed', {
                type: 'success',
                top: true,
                right: true,
                timeout: 3000,
              })
              this.$emit('reload')
              this.$emit('close')
            } catch (e) {
              this.$toast('role remove failed', {
                type: 'error',
                top: true,
                right: true,
                timeout: 3000,
              })
            }

          }
        })
    },

    close() {
      this.$emit('close')
    },
  },

}
</script>

<style scoped>

</style>
