<template>
  <v-card height="">
    <v-card-title>
      Calendar
      <v-spacer />

      <v-btn
        icon
        small
        @click="prev()"
      >
        <v-icon>
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <v-btn
        outlined
        small
        @click="setToday()"
      >
        today
      </v-btn>
      <v-btn
        icon
        small
        @click="next()"
      >
        <v-icon>
          mdi-chevron-right
        </v-icon>
      </v-btn>
      <v-menu
        bottom
        right
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-6"
            small
            outlined
            v-bind="attrs"
            v-on="on"
          >
            <span>{{ typeToLabel[type] }}</span>
            <v-icon right>
              mdi-menu-down
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="type = 'month'">
            <v-list-item-title>Month</v-list-item-title>
          </v-list-item>
          <v-list-item @click="type = 'week'">
            <v-list-item-title>Week</v-list-item-title>
          </v-list-item>

        </v-list>
      </v-menu>
    </v-card-title>
    <v-card-text>
      <v-sheet
        tile
        class="d-flex"
      >
        <v-spacer />
        <span v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                </span>
        <span
          v-if="type === 'week'"
          class="ml-4"
        >
                    KW{{ getKW() }}
                </span>
        <v-spacer />
      </v-sheet>
      <v-sheet height="700px">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          locale="de"
          :weekdays="weekday"
          :events="calendarEntries"
          :event-color="getEventColor"
          :type="type"
          event-overlap-mode="column"
          :event-overlap-threshold="45"
          class="small-day-labels"
          @click:event="showEvent"
          @click:date="addEvent"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          dark
        >
          <NewEventDialog
            :event="selectedEvent"
            :is-new="false"
            :is-dashboard="true"
            @close="closeSelecetedEvent"
            @save="saveSelectedEvent"
          />

        </v-menu>
        <v-dialog
          v-model="newEventDialog"
          persistent
          max-width="800px"
        >
          <NewEventDialog
            :event="newEvent"
            :is-new="true"
            :is-dashboard="true"
            @close="closeNewEvent"
            @save="saveNewEvent"
          />
        </v-dialog>
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from 'lodash'
import NewEvent from '../../types/NewEvent'
import {mapActions, mapState} from 'vuex'
import NewEventDialog from '../ProjectManager/NewEventDialog'
import moment from 'moment'

export default {
  name: 'DashboardCalendar',
  components: {NewEventDialog},
  data() {
    return {
      weekday: [1, 2, 3, 4, 5, 6, 0],
      type: 'month',
      focus: '',
      data: {
        projectTimetables: [],
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      selectedEdit: false,
      writebackEvent: {},
      newEvent: {},
      newEventDialog: false,
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      typeToLabel: {
        month: 'Month',
        week: 'Week',
      },
    }
  },
  computed: {
    ...mapState('admin', ['calendarEntries'])
  },
  mounted() {
    this.focus = moment().format('YYYY-MM-DD')
  },
  methods: {
    ...mapActions('project', ['saveTimetable', 'createNewEvent', 'updateEvent']),
    getEventColor(event) {
      return event.color
    },
    showEvent({nativeEvent, event}) {
      const open = () => {
        this.writebackEvent = event
        this.selectedEvent = _.clone(event)
        this.selectedEvent.startdate = this.selectedEvent.start.split(' ')[0]
        this.selectedEvent.starttime = this.selectedEvent.start.split(' ')[1]
        this.selectedEvent.enddate = this.selectedEvent.end.split(' ')[0]
        this.selectedEvent.endtime = this.selectedEvent.end.split(' ')[1]
        this.selectedElement = nativeEvent.target
        this.selectedOpen = true
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    setToday() {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    addEvent({date}) {
      console.log('date', date)
      this.newEvent = new NewEvent(date)
      this.newEventDialog = true
    },
    closeSelecetedEvent() {
      this.selectedOpen = false
    },
    saveSelectedEvent(event) {
      event.start = event.startdate + ' ' + event.starttime
      event.end = event.enddate + ' ' + event.endtime

      this.updateEvent(event)
      this.selectedOpen = false
    },
    closeNewEvent() {
      this.newEventDialog = false
    },
    saveNewEvent(event) {
      event.start = event.startdate + ' ' + event.starttime
      event.end = event.enddate + ' ' + event.endtime
      event.id = null
      console.log('save Event Dashboard')

      this.createNewEvent(event)
      this.newEventDialog = false
      this.focus = this.newEvent.startdate
    },
    getKW() {
      if (this.focus) {
        return moment(this.focus, 'YYYY-MM-DD').week()
      }
      return moment().week()
    }
  }

}
</script>

<style scoped>
</style>
