<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="6" >
                <DashboardCalendar/>
            </v-col>
            <v-col cols="12" md="6">
                <v-tabs
                        v-model="tab"
                        fixed-tabs
                        dark
                        color="white"
                >
                    <v-tab href="#details">
                        Host Details
                    </v-tab>
                    <v-tab href="#instant">
                        Instantmessages
                    </v-tab>
                    <v-tab href="#users">
                        Users
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item value="details" background-color="green">
                        <Hostdetails/>
                    </v-tab-item>
                    <v-tab-item value="instant">
                        <InstantmessagesCard/>
                    </v-tab-item>
                    <v-tab-item value="users">
                        <Users/>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import Users from '../components/Admindashboard/Users';
    import {mapActions} from 'vuex';
    import Hostdetails from '../components/Admindashboard/Hostdetails';
    import InstantmessagesCard from '../components/Admindashboard/Instantmessages/InstantmessagesCard';
    import DashboardCalendar from '../components/Admindashboard/DashboardCalendar';

    export default {
        name: 'AdminDashboard',
        components: {
            DashboardCalendar,
            InstantmessagesCard,
            Hostdetails,
            Users
        },
        data() {
            return {
                tab: ''
            }
        },
        mounted() {
            this.loadUsers();
            this.loadHostdetails();
            this.loadCalendarEntries();
        },
        methods: {
            ...mapActions('admin', ['loadUsers', 'loadHostdetails', 'loadCalendarEntries'])
        }
    }
</script>

<style scoped>

</style>
