<template>
  <v-dialog
    :value="visible"
    persistent
    max-width="500px"
  >
    <v-card>
      <v-card-title>
        Role
      </v-card-title>
      <v-card-text>
        <v-form ref="role">
          <v-select
            v-model="selected_role"
            label="role"
            :items="roles"
            item-value="id"
            item-text="description"
            return-object
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          depressed
          small
          color="primary"
          @click="add"
        >
          save
        </v-btn>
        <v-btn
          depressed
          small
          color="priamry"
          @click="close"
        >
          close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import globalService from '@/services/Globals.service';

export default {
  name: 'UserRoleDialog',
  props: {
    visible: Boolean
  },
  data() {
    return {
      selected_role: null,
      roles: [],
      loading_roles: false
    }
  },
  computed: {
  },
  watch: {
    visible(val) {
      if (val) {
        this.selected_role = null
        this.loadRoles()
      }

    }
  },
  methods: {
    ...mapActions('admin', ['addRoleToUser']),
    ...mapMutations('admin', ['setDialogsUserRole']),

    async loadRoles() {
      this.loading_roles = true
      this.roles = await globalService.loadRoles()
      this.loading_roles = false
    },

    add() {
      this.$emit('addRole', this.selected_role);
      this.close()
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
