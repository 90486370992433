<template>
  <v-card
    height="500px"
    match-height
    scrollable
    :loading="loaders.hostdetail"
  >
    <v-card-title>
      Host details
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-row>
          <v-col cols="5">
            <v-form lazy-validation>
              <v-text-field
                v-model="form.pin"
                label="PIN"
                readonly
                append-icon="mdi-autorenew"
                hint="pins are always autogenerated to ensure maximum securtity"
                @click:append="generateNewPin"
              />
              <v-text-field
                v-model="form.name"
                label="name"
              />

              <v-autocomplete
                v-model="form.font"
                label="font"
                :items="available_fonts"
              />
            </v-form>
          </v-col>

          <v-col cols="7">
            <v-toolbar
              flat
              :color="form.header_color"
            >
              <v-toolbar-title :color="form.font_color">
                                <span :style="{color: form.font_color, 'font-family': form.font}">
                                    {{ form.name }}
                                </span>
                <v-subheader
                  class="text-right"
                  :style="{color: form.font_color, height: 'auto'}"
                >
                  powered by opera.guru
                </v-subheader>
              </v-toolbar-title>
            </v-toolbar>

            <v-card
              v-show="false"
              flat
              :color="form.background_color"
            >
              <v-card-text>
                <span :style="{color: form.body_font_color}">
                    Lorem Ipsum dolor sit amet.
                </span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">

            <v-simple-table dense>
              <tbody>
              <tr>
                <td>
                  Header Color
                </td>
                <td>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-chip
                        :color="form.header_color"
                        dark
                        small
                        v-on="on"
                      >
                      </v-chip>
                    </template>
                    <v-color-picker
                      v-model="selected_color.header_color"
                      hide-canvas
                      hide-inputs
                      show-swatches
                      class="mx-auto"
                      @input="changeColor('header_color')"
                    ></v-color-picker>
                  </v-menu>
                </td>
                <td />
                <td v-show="false">
                  Background Color
                </td>
                <td v-show="false">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <template>
                        <v-chip
                          :color="form.background_color"
                          dark
                          small
                          v-on="on"
                        />
                      </template>
                    </template>
                    <v-color-picker
                      v-model="selected_color.background_color"
                      hide-canvas
                      hide-inputs
                      show-swatches
                      class="mx-auto"
                      @input="changeColor('background_color')"
                    ></v-color-picker>
                  </v-menu>
                </td>
              </tr>
              <tr>
                <td>
                  Header Font Color
                </td>
                <td>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-chip
                        :color="form.font_color"
                        dark
                        small
                        v-on="on"
                      >
                      </v-chip>
                    </template>
                    <v-color-picker
                      v-model="selected_color.font_color"
                      hide-canvas
                      hide-inputs
                      show-swatches
                      class="mx-auto"
                      @input="changeColor('font_color')"
                    ></v-color-picker>
                  </v-menu>
                </td>
                <td />
                <td v-show="false">
                  Body Font Color
                </td>
                <td v-show="false">
                  <v-menu>
                    <template v-slot:activator="{ on }">
                      <template>
                        <v-chip
                          :color="form.body_font_color"
                          dark
                          small
                          v-on="on"
                        />
                      </template>
                    </template>
                    <v-color-picker
                      v-model="selected_color.body_font_color"
                      hide-canvas
                      hide-inputs
                      show-swatches
                      class="mx-auto"
                      @input="changeColor('body_font_color')"
                    ></v-color-picker>
                  </v-menu>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  Contrast ratio: {{
                    contrast(hexToRgb(form.font_color),
                      hexToRgb(form.header_color)).toFixed(2)
                  }}
                </td>
                <td />
                <td
                  colspan="2"
                  v-show="false"
                >
                  Contrast ratio: {{
                    contrast(hexToRgb(form.body_font_color),
                      hexToRgb(form.background_color)).toFixed(2)
                  }}
                </td>
              </tr>
              <tr>
                <td colspan="5">
                  <span>Info: contrast ratio should be higher than 7 to ensure a good readability</span>
                </td>
              </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        depressed
        small
        color="primary"
        @click="save"
      >
        save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import _ from 'lodash'
import ValidationRulesMixin from '@/mixins/ValidationrulesMixin'

export default {
  name: 'Hostdetails',
  mixins: [ValidationRulesMixin],
  data() {
    return {
      api_key: 'AIzaSyCWaBISj-ITorhGqfWfYtBbcUyoPc_7bWQ',
      available_fonts: ['Roboto', 'Pacifico', 'Dancing Script', 'Shadows Into Light', 'Lobster', 'Anton', 'Indie Flower', 'Charmonman', 'Kodchasan', 'Notable', 'Mali', 'Srisakdi', 'Slabo 27px'],
      form: {
        pin: '',
        font_color: '#1E1E1E',
        header_color: '#1E1E1E',
        background_color: '#1E1E1E',
        body_font_color: '#1E1E1E'
      },
      selected_color: {
        font_color: '#1E1E1E',
        header_color: '#1E1E1E',
        background_color: '#1E1E1E',
        body_font_color: '#1E1E1E'
      },
    }
  },
  computed: {
    ...mapState('admin', ['loaders']),
    ...mapState('admin', ['hostdetail'])
  },
  watch: {
    hostdetail() {
      this.form = _.clone(this.hostdetail)
    }
  },
  methods: {
    ...mapActions('admin', ['updateHostdetails', 'generateNewPin']),


    luminanace(r, g, b) {
      const a = [r, g, b].map(function (v) {
        v /= 255
        return v <= 0.03928
          ? v / 12.92
          : Math.pow((v + 0.055) / 1.055, 2.4)
      })
      return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
    },
    contrast(rgb1 = {
      r: 0,
      g: 0,
      b: 0
    }, rgb2 = {
      r: 0,
      g: 0,
      b: 0
    }) {
      if (!rgb1 || !rgb2) {
        return 0
      }
      const lum1 = this.luminanace(rgb1.r, rgb1.g, rgb1.b)
      const lum2 = this.luminanace(rgb2.r, rgb2.g, rgb2.b)
      const brightest = Math.max(lum1, lum2)
      const darkest = Math.min(lum1, lum2)
      return (brightest + 0.05) / (darkest + 0.05)
    },
    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null
    },
    save() {
      this.updateHostdetails(this.form)
    },
    changeColor(field) {
      console.log(this.selected_color[field])
      this.form[field] = _.clone(this.selected_color[field])
    }
  }
}
</script>

<style scoped>

</style>
